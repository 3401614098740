<template>
  <div id="placeorder">

    <div class="placeorder-content">
      <Address ref="address" />
      <Order :cardlist="showCardList" />
    </div>
 
    <div class="footer">
      <div class="footer-btn">
        <p class="total-money">
          <span class="total-order">应付总额：</span>
          <span class="money">¥{{ actualAmount.toFixed(2) }}</span>
          <span class="total-order">(共{{totalAmount}}件)</span>
        </p>
        <!-- <button class="order-btn" @click="remove">取消订单</button> -->
        <p class="total-btn">
          <button class="pay-btn" :disabled="butdisabled" @click="addorder">
            提交订单
          </button>
        </p>
      </div>
    </div>

    <van-loading
     size="24px"
     v-show="placeorder"
     vertical
    >
    加载中...
    </van-loading>
  </div>
</template>

<script>
import Order from "./components/card.vue";
import Address from "./components/address.vue";
import { Toast } from "vant";
import { queryMarketList, caculatePrice, submitorder } from "./service";
const coupon = {
  available: 1,
  condition: "无使用门槛\n最多优惠12元",
  reason: "",
  value: 150,
  name: "优惠券名称",
  startAt: 1489104000,
  endAt: 1514592000,
  valueDesc: "1.5",
  unitDesc: "元",
};

export default {
  components: { Order, Address },
  data() {
    return {
      placeorder: false,
      butdisabled: false,
      chosenCoupon: -1,
      coupons: [coupon],
      disabledCoupons: [coupon],
      showList: false,
      show: false,
      cardlist: [],
      storeName: "",
      storeObj: {},
      actualAmount: 0,
      totalAmount:'',
      calStep: 1,
      orderAddressReqDto: {
        receiver: "",
        phoneNumber: "",
        detailAddress: "",
      },
      showCardList: [],
    };
  },
  methods: {
    // 计算价格
    async calculatePrice1(list) {
      const obj = {};
      obj.orderLineList = [];
      list.forEach((i) => {
        obj.calStep = 1;
        if (Array.isArray(i.cartLineResDtoList)) {
          i?.cartLineResDtoList?.forEach((j) => {
            if (j.isChoice === 1) {
              obj.orderLineList.push({
                id: j.id,
                skuCode: j.skuCode,
                storeCode: j.storeCode,
              });
            }
          });
          i?.exchangeCartLineList?.forEach((j) => {
            if (j.isChoice === 1) {
              obj.orderLineList.push({
                id: j.id,
                skuCode: j.skuCode,
                storeCode: j.storeCode,
              });
            }
          });
        }
      });
      const res = await caculatePrice(obj);
      if (res.status === "0") {
        const {
          data: { totalActualAmount,totalCount, promotions },
        } = res;
        this.actualAmount = totalActualAmount;
        this.totalAmount = totalCount;
        this.showCardList = promotions;

        //list的item的cartLineResDtoList
        //order的item的orderLineList
        const rules = []
        promotions.map((item)=>{
          item.order.orderLineList.map(item2=>{
            if(item2.promotionRules){
              rules.push({skuCode:item2.skuCode,promotionRules:item2.promotionRules.map((item3)=>{
                return item3.activityNo
              })})
            }
          })
        })
        this.cardlist.forEach((item)=>{
          item.cartLineResDtoList.forEach(item2=>{
            let promotionRemark = (rules.filter(v=>{
              return v.skuCode === item2.skuCode
            })).map(temp=>{
              return temp.promotionRules
            })
            item2.promotionRemark= (promotionRemark.toString())
          })
        })
      }
    },
    // 获取列表
    async getMarketList() {
      this.placeorder = true;
      const res = await queryMarketList();
      this.placeorder = false;
      if (res.status === "0") {
        this.cardlist = res?.data?.items.map((d) => ({
          ...d,
          cartLineResDtoList: d.cartLineResDtoList.filter(
            (d) => d.isChoice === 1
          ),
        }));
        this.cardlist = this.cardlist.filter(
          (d) => d?.cartLineResDtoList?.length > 0
        );
        this.calculatePrice1(this.cardlist);
      } else {
        Toast(res.msg);
      }
    },
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
    },
    onExchange() {
      this.coupons.push(coupon);
    },

    async addorder() {
      if (!this?.$refs?.address?.defaultAddress?.length) {
        Toast("请先选择地址");
        return;
      }
      const orderLineList = [];
      this.cardlist.forEach((i) => {
        if (Array.isArray(i.cartLineResDtoList)) {
          i.cartLineResDtoList.forEach((j) => {
            if (j.isChoice === 1) {
              orderLineList.push({
                ...j,
                id: j.id,
                storeCode: j.storeCode,
              });
            }
          });
        }
        if (Array.isArray(i.exchangeCartLineList)) {
          i.exchangeCartLineList.forEach((j) => {
            if (j.isChoice === 1) {
              orderLineList.push({
                ...j,
                id: j.id,
                storeCode: j.storeCode,
                exchangeItem: true,
              });
            }
          });
        }
      });
      this.orderAddressReqDto = this.$refs.address.defaultAddress[0];
      this.orderAddressReqDto.receiver =
        this.$refs.address.defaultAddress[0].consignee;
      this.orderAddressReqDto.receiveType =
        this.$refs.address.defaultAddress[0].zipcode;
      this.orderAddressReqDto.detailAddress =
        this.$refs.address.defaultAddress[0].detail;
      this.orderAddressReqDto.phoneNumber =
        this.$refs.address.defaultAddress[0].phone;
      const params = {
        orderAddressReqDto: this.orderAddressReqDto,
        orderIdOut: "string",
        orderLineReqDtoList: orderLineList,
        remark: "string",
        sellerCode: this.storeObj.storeCode,
        orderAmount: this.actualAmount,
        actualAmount: this.actualAmount,
        tradeType: 54,
        orderSource: 2, // 订单来源 1为pc 2为h5
      };
      this.butdisabled = true;
      const res = await submitorder(params);
      if (res.status === "0") {
        Toast("提交成功");
        this.onClickLeft();
        this.$router.push({
          path: `/pay`,
          query: {
            id: res.data.parentOrderNo,
            orderNo:res.data.orderNo,
            storeCode:res.data.storeCode
          },
        });
      } else {
        Toast(res.msg);
        this.butdisabled = false;
      }
    },
    onClickLeft() {
      this.$router.push("/market");
    },
    poPKefu() {
      window.kefuButton.click();
    },
  },

  mounted() {
    this.getMarketList();
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
</style>
<style lang="less" scoped>
#placeorder {
  background: #f5f6f7;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .placeorder-content{
     width: 100%;
     flex: 1 1 0;
     height: 100%;
     overflow: auto;
  }

  .footer {
    height: 50px;
    line-height: 49px;
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    background: #ffffff;
    margin-top: 8px;
    .footer-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      .total-money {
        margin-left: 12px;
        .total-order {
          font-weight: 400;
          font-size: 12px;
          color: #96979b;
          position: relative;
          left: 5px;
        }
        .money {
          font-size: 16px;
          font-weight: 600;
          color: #ff3228;
          font-family: "Montserrat", sans-serif;
        }
      }
      .total-btn {
        padding: 0 14px;
        .pay-btn {
          width: 100px;
          height: 36px;
          line-height: 36px;
          background: #0095da;
          border-radius: 12px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: #ffffff;
          border: 0;
        }
      }
    }
  }
}
</style>
